import React from 'react';

const ActivePhoneSvg = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#F6FBFF"/>
        <path d="M22.72 20.6667C22.8 21.8533 23 23.0133 23.32 24.12L21.72 25.72C21.1733 24.12 20.8267 22.4267 20.7067 20.6667H22.72ZM35.8667 36.6933C37 37.0133 38.16 37.2133 39.3333 37.2933V39.28C37.5733 39.16 35.88 38.8133 34.2667 38.28L35.8667 36.6933ZM24 18H19.3333C18.6 18 18 18.6 18 19.3333C18 31.8533 28.1467 42 40.6667 42C41.4 42 42 41.4 42 40.6667V36.0133C42 35.28 41.4 34.68 40.6667 34.68C39.0133 34.68 37.4 34.4133 35.9067 33.92C35.7733 33.8667 35.6267 33.8533 35.4933 33.8533C35.1467 33.8533 34.8133 33.9867 34.5467 34.24L31.6133 37.1733C27.84 35.24 24.7467 32.16 22.8267 28.3867L25.76 25.4533C26.1333 25.08 26.24 24.56 26.0933 24.0933C25.6 22.6 25.3333 21 25.3333 19.3333C25.3333 18.6 24.7333 18 24 18Z" fill="#439ADE"/>
    </svg>
);

const InactivePhoneSvg = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="white"/>
        <path d="M22.72 20.6667C22.8 21.8533 23 23.0133 23.32 24.12L21.72 25.72C21.1733 24.12 20.8267 22.4267 20.7067 20.6667H22.72ZM35.8667 36.6933C37 37.0133 38.16 37.2133 39.3333 37.2933V39.28C37.5733 39.16 35.88 38.8133 34.2667 38.28L35.8667 36.6933ZM24 18H19.3333C18.6 18 18 18.6 18 19.3333C18 31.8533 28.1467 42 40.6667 42C41.4 42 42 41.4 42 40.6667V36.0133C42 35.28 41.4 34.68 40.6667 34.68C39.0133 34.68 37.4 34.4133 35.9067 33.92C35.7733 33.8667 35.6267 33.8533 35.4933 33.8533C35.1467 33.8533 34.8133 33.9867 34.5467 34.24L31.6133 37.1733C27.84 35.24 24.7467 32.16 22.8267 28.3867L25.76 25.4533C26.1333 25.08 26.24 24.56 26.0933 24.0933C25.6 22.6 25.3333 21 25.3333 19.3333C25.3333 18.6 24.7333 18 24 18Z" fill="#010C15" fillOpacity="0.2"/>
    </svg>
);

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.72 2.66667C4.8 3.85333 5 5.01333 5.32 6.12L3.72 7.72C3.17333 6.12 2.82667 4.42667 2.70667 2.66667H4.72ZM17.8667 18.6933C19 19.0133 20.16 19.2133 21.3333 19.2933V21.28C19.5733 21.16 17.88 20.8133 16.2667 20.28L17.8667 18.6933ZM6 0H1.33333C0.6 0 0 0.6 0 1.33333C0 13.8533 10.1467 24 22.6667 24C23.4 24 24 23.4 24 22.6667V18.0133C24 17.28 23.4 16.68 22.6667 16.68C21.0133 16.68 19.4 16.4133 17.9067 15.92C17.7733 15.8667 17.6267 15.8533 17.4933 15.8533C17.1467 15.8533 16.8133 15.9867 16.5467 16.24L13.6133 19.1733C9.84 17.24 6.74667 14.16 4.82667 10.3867L7.76 7.45333C8.13333 7.08 8.24 6.56 8.09333 6.09333C7.6 4.6 7.33333 3 7.33333 1.33333C7.33333 0.6 6.73333 0 6 0Z" fill="#010C15" fill-opacity="0.2"/>
</svg>


export { ActivePhoneSvg, InactivePhoneSvg }