import React from 'react';

const MailSentSvg = () => (
    <svg width="50" height="50" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M9.96066 3.80402C9.59754 3.80402 1.24567 3.80402 0.483378 3.80402C0.277754 3.80402 0.110962 3.97081 0.110962 4.17643V10.6439C0.110962 10.8496 0.277754 11.0163 0.483378 11.0163H9.96066C10.1664 11.0163 10.3331 10.8495 10.3331 10.6439V4.17652C10.3331 3.97081 10.1664 3.80402 9.96066 3.80402Z" fill="#FDA537"/>
        <path d="M0.810708 10.6439V4.17652C0.810708 3.97081 0.977414 3.80402 1.18312 3.80402C0.834424 3.80402 0.58771 3.80402 0.483378 3.80402C0.277754 3.80402 0.110962 3.97081 0.110962 4.17652V10.6439C0.110962 10.8496 0.277754 11.0163 0.483378 11.0163H1.18312C0.977501 11.0163 0.810708 10.8496 0.810708 10.6439Z" fill="#F9972D"/>
        <path d="M5.44458 7.99389C5.31393 8.09648 5.13011 8.09648 4.99946 7.99389L4.22969 7.38953L0.110962 10.6438C0.110962 10.8495 0.277754 11.0162 0.483378 11.0162H9.96066C10.1663 11.0162 10.3331 10.8495 10.3331 10.6438L6.21435 7.38953L5.44458 7.99389Z" fill="#FEB644"/>
        <path d="M0.483378 11.0159H1.18312C0.977414 11.0159 0.810708 10.8492 0.810708 10.6435V10.0906L0.110962 10.6435C0.110962 10.8492 0.277754 11.0159 0.483378 11.0159Z" fill="#FDA537"/>
        <path d="M9.96066 3.80402C9.73428 3.80402 0.75346 3.80402 0.483378 3.80402C0.277754 3.80402 0.110962 3.97081 0.110962 4.17643L4.99946 8.01448C5.13011 8.11708 5.31393 8.11708 5.44458 8.01448L10.3331 4.17643C10.3331 3.97081 10.1664 3.80402 9.96066 3.80402Z" fill="#FFC850"/>
        <path d="M0.810708 4.7258V4.17643C0.810708 3.97081 0.977414 3.80402 1.18312 3.80402C0.834424 3.80402 0.58771 3.80402 0.483378 3.80402C0.414316 3.80402 0.34977 3.82321 0.29426 3.85596C0.184715 3.92086 0.110962 4.03987 0.110962 4.17643L0.810708 4.7258Z" fill="#FEB644"/>
        <path d="M11.4973 3.47052C11.4973 4.52592 10.6417 5.3816 9.58625 5.3816C8.53085 5.3816 7.67517 4.52592 7.67517 3.47052C7.67517 2.41504 8.53085 1.55945 9.58625 1.55945C10.6417 1.55945 11.4973 2.41504 11.4973 3.47052Z" fill="#F25C5C"/>
        <path d="M8.36249 3.47052C8.36249 2.53249 9.03861 1.75282 9.92991 1.5909C9.81836 1.57057 9.70369 1.55945 9.58624 1.55945C8.53076 1.55945 7.67517 2.41504 7.67517 3.47052C7.67517 4.52601 8.53085 5.3816 9.58624 5.3816C9.70369 5.3816 9.81836 5.37039 9.92991 5.35015C9.03861 5.18823 8.36249 4.40856 8.36249 3.47052Z" fill="#E54A4A"/>
        <path d="M9.58613 4.5309C9.49127 4.5309 9.4143 4.45393 9.4143 4.35907V2.95011H9.38094C9.28607 2.95011 9.20911 2.87323 9.20911 2.77828C9.20911 2.68341 9.28607 2.60645 9.38094 2.60645H9.58613C9.68099 2.60645 9.75796 2.68333 9.75796 2.77828V4.35898C9.75796 4.45393 9.68108 4.5309 9.58613 4.5309Z" fill="#E4EAF8"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="11.3864" height="11.3864" fill="white" transform="translate(0.133179 0.580933)"/>
            </clipPath>
        </defs>
    </svg>
);

export default MailSentSvg;