import React from 'react';

export const Google = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9236 8.23233C15.1355 6.67294 10.6606 4.16892 3.71975 0.282547C3.2518 -0.0265004 2.71339 -0.0670004 2.2482 0.090453L14.1568 11.9991L17.9236 8.23233Z" fill="#32BBFF"/>
        <path d="M2.24821 0.0904541C2.16103 0.119985 2.07618 0.155611 1.99504 0.198642C1.48134 0.476517 1.10385 1.01122 1.10385 1.68749V22.3107C1.10385 22.9869 1.48129 23.5216 1.99504 23.7995C2.07604 23.8424 2.16084 23.8782 2.24793 23.9079L14.1568 11.9991L2.24821 0.0904541Z" fill="#32BBFF"/>
        <path d="M14.1569 11.9991L2.24796 23.9079C2.71328 24.0666 3.25169 24.0292 3.71979 23.7156C10.451 19.9464 14.8754 17.472 17.6957 15.8994C17.7742 15.8553 17.8511 15.8121 17.9272 15.7694L14.1569 11.9991Z" fill="#32BBFF"/>
        <path d="M1.10385 11.9991V22.3107C1.10385 22.9869 1.48129 23.5217 1.99504 23.7995C2.07604 23.8425 2.16084 23.8782 2.24793 23.908L14.1568 11.9991H1.10385Z" fill="#2C9FD9"/>
        <path d="M3.71981 0.28262C3.1641 -0.0843184 2.50879 -0.0738652 1.99509 0.19876L13.9761 12.1798L17.9236 8.2324C15.1356 6.67301 10.6607 4.16899 3.71981 0.28262Z" fill="#29CC5E"/>
        <path d="M13.9762 11.8184L1.99509 23.7994C2.50884 24.072 3.1641 24.0878 3.71981 23.7156C10.451 19.9464 14.8754 17.472 17.6957 15.8994C17.7742 15.8552 17.8511 15.8121 17.9272 15.7694L13.9762 11.8184Z" fill="#D93F21"/>
        <path d="M22.8962 11.9991C22.8962 11.4277 22.6078 10.851 22.0364 10.5312C22.0364 10.5312 20.9677 9.93491 17.6919 8.10266L13.7955 11.9991L17.6957 15.8993C20.9355 14.0802 22.0363 13.4669 22.0363 13.4669C22.6078 13.1471 22.8962 12.5705 22.8962 11.9991Z" fill="#FFD500"/>
        <path d="M22.0364 13.4669C22.6078 13.1472 22.8962 12.5705 22.8962 11.9991H13.7955L17.6957 15.8993C20.9356 14.0803 22.0364 13.4669 22.0364 13.4669Z" fill="#FFAA00"/>
    </svg>
);

export const  Apple = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.68383 24C5.11191 23.9794 2.15729 16.6879 2.15729 12.9745C2.15729 6.90873 6.70768 5.58077 8.46137 5.58077C9.25168 5.58077 10.0956 5.89112 10.8399 6.16577C11.3604 6.35734 11.8987 6.55511 12.1981 6.55511C12.3773 6.55511 12.7996 6.38688 13.1724 6.23927C13.9676 5.92272 14.957 5.5293 16.1092 5.5293C16.1113 5.5293 16.114 5.5293 16.1161 5.5293C16.9764 5.5293 19.585 5.71811 21.1533 8.0733L21.5206 8.62534L20.9919 9.0243C20.2366 9.5942 18.8585 10.6338 18.8585 12.6931C18.8585 15.132 20.4193 16.07 21.1691 16.5211C21.5001 16.7202 21.8427 16.9255 21.8427 17.3746C21.8427 17.6678 19.5026 23.9636 16.1044 23.9636C15.2729 23.9636 14.6851 23.7137 14.1667 23.4933C13.6421 23.2701 13.1896 23.0779 12.4418 23.0779C12.0628 23.0779 11.5835 23.2571 11.0761 23.4473C10.3826 23.7061 9.59776 24 8.70718 24H8.68383Z" fill="black"/>
        <path d="M16.4692 0C16.5577 3.19106 14.2756 5.40488 11.9962 5.26603C11.6206 2.71945 14.2753 0 16.4692 0Z" fill="black"/>
    </svg>
);