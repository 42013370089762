import React from 'react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ErrorModal({ errMsg, errorType, continueToPortal }) {
  return (
    <>
      <div className='w-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='w-64 rounded-lg shadow-lg bg-white'>
          <div className='block text-center w-full p-6'>
            <FontAwesomeIcon icon={faBan} className='text-red-400' />
            <div className='text-xs sm:text-sm text-red-500 font-semibold'>
              {errMsg} <br />
            </div>
            {
              (errorType = 'browser' ? (
                <div className='text-center text-blue-400 mt-4'>
                  <span
                    className='hover:text-blue-700 cursor-pointer hover:underline'
                    onClick={() => continueToPortal()}
                  >
                    Continue to login
                  </span>
                </div>
              ) : (
                <div className='text-center text-blue-500 mt-4'>
                  <span
                    className='hover:text-blue-700 cursor-pointer hover:underline'
                    onClick={() => window.location.reload()}
                  >
                    Try a refresh
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className='opacity-60 fixed inset-0 z-40 bg-black' />
    </>
  );
}
