import React from 'react';

const OTPLockSvg = () => (
    <svg width="60" height="60" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M11.186 7.97156H0.466763C0.279904 7.97156 0.133179 8.11828 0.133179 8.30514V11.6632C0.133179 11.85 0.279904 11.9968 0.466763 11.9968H11.186C11.3728 11.9968 11.5195 11.85 11.5195 11.6632V8.30514C11.5195 8.11828 11.3728 7.97156 11.186 7.97156Z" fill="#575F64"/>
        <path d="M11.5194 8.30514V11.6632C11.5194 11.85 11.3726 11.9968 11.1858 11.9968H5.82617V7.97156H11.1858C11.3726 7.97156 11.5194 8.11828 11.5194 8.30514Z" fill="#32393F"/>
        <path d="M7.49454 2.2784V3.63498C7.49454 3.82175 7.34781 3.96856 7.16095 3.96856C6.97409 3.96856 6.82737 3.82175 6.82737 3.63498V2.2784C6.82737 1.72459 6.38042 1.27764 5.82662 1.27764C5.27281 1.27764 4.82586 1.72459 4.82586 2.2784V3.63498C4.82586 3.82175 4.67914 3.96856 4.49228 3.96856C4.30542 3.96856 4.15869 3.82175 4.15869 3.63498V2.2784C4.15869 1.35774 4.90596 0.610474 5.82662 0.610474C6.74727 0.610474 7.49454 1.35774 7.49454 2.2784Z" fill="#575F64"/>
        <path d="M5.82617 1.27764V0.610474C6.74683 0.610474 7.4941 1.35774 7.4941 2.2784V3.63498C7.4941 3.82175 7.34737 3.96856 7.16051 3.96856C6.97365 3.96856 6.82693 3.82175 6.82693 3.63498V2.2784C6.82693 1.72459 6.37997 1.27764 5.82617 1.27764Z" fill="#32393F"/>
        <path d="M7.82757 3.30139H3.82455C3.63769 3.30139 3.49097 3.44812 3.49097 3.63498V6.97082C3.49097 7.1576 3.63769 7.30441 3.82455 7.30441H7.82757C8.01443 7.30441 8.16115 7.1576 8.16115 6.97082V3.63498C8.16115 3.44812 8.01443 3.30139 7.82757 3.30139Z" fill="#FDBF00"/>
        <path d="M8.16127 3.63498V6.97082C8.16127 7.1576 8.01454 7.30441 7.82768 7.30441H5.82617V3.30139H7.82768C8.01454 3.30139 8.16127 3.44812 8.16127 3.63498Z" fill="#FF9100"/>
        <path d="M3.1978 9.12739C3.06749 8.99709 2.85639 8.99709 2.72609 9.12739L2.49023 9.36325L2.25438 9.12739C2.12407 8.99709 1.91297 8.99709 1.78267 9.12739C1.65236 9.2577 1.65236 9.4688 1.78267 9.5991L2.01852 9.83496L1.78267 10.0708C1.65236 10.2011 1.65236 10.4122 1.78267 10.5425C1.91297 10.6728 2.12407 10.6728 2.25438 10.5425L2.49023 10.3067L2.72609 10.5425C2.85639 10.6728 3.06749 10.6728 3.1978 10.5425C3.3281 10.4122 3.3281 10.2011 3.1978 10.0708L2.96194 9.83496L3.1978 9.5991C3.3281 9.4688 3.3281 9.2577 3.1978 9.12739Z" fill="#9CDD05"/>
        <path d="M5.19963 9.12739C5.06932 8.99709 4.85822 8.99709 4.72792 9.12739L4.49206 9.36325L4.25621 9.12739C4.1259 8.99709 3.9148 8.99709 3.7845 9.12739C3.65419 9.2577 3.65419 9.4688 3.7845 9.5991L4.02035 9.83496L3.7845 10.0708C3.65419 10.2011 3.65419 10.4122 3.7845 10.5425C3.9148 10.6728 4.1259 10.6728 4.25621 10.5425L4.49206 10.3067L4.72792 10.5425C4.85822 10.6728 5.06932 10.6728 5.19963 10.5425C5.32993 10.4122 5.32993 10.2011 5.19963 10.0708L4.96377 9.83496L5.19963 9.5991C5.32993 9.4688 5.32993 9.2577 5.19963 9.12739Z" fill="#9CDD05"/>
        <path d="M6.96524 9.83496L7.20109 9.5991C7.3314 9.4688 7.3314 9.2577 7.20109 9.12739C7.07079 8.99709 6.85969 8.99709 6.72938 9.12739L6.49353 9.36325L6.25767 9.12739C6.12737 8.99709 5.91627 8.99709 5.78596 9.12739C5.65566 9.2577 5.65566 9.4688 5.78596 9.5991L6.02182 9.83496L5.78596 10.0708C5.65566 10.2011 5.65566 10.4122 5.78596 10.5425C5.91627 10.6728 6.12737 10.6728 6.25767 10.5425L6.49353 10.3067L6.72938 10.5425C6.85969 10.6728 7.07079 10.6728 7.20109 10.5425C7.3314 10.4122 7.3314 10.2011 7.20109 10.0708L6.96524 9.83496Z" fill="#66BB00"/>
        <path d="M9.16225 10.6401H8.49508C8.31074 10.6401 8.1615 10.4909 8.1615 10.3065C8.1615 10.1222 8.31074 9.97296 8.49508 9.97296H9.16225C9.34659 9.97296 9.49584 10.1222 9.49584 10.3065C9.49584 10.4909 9.34659 10.6401 9.16225 10.6401Z" fill="#66BB00"/>
        <path d="M6.15997 4.96908V5.63625C6.15997 5.82303 6.01324 5.96984 5.82638 5.96984C5.63952 5.96984 5.4928 5.82303 5.4928 5.63625V4.96908C5.4928 4.78222 5.63952 4.6355 5.82638 4.6355C6.01324 4.6355 6.15997 4.78222 6.15997 4.96908Z" fill="#7C8388"/>
        <path d="M6.15976 4.96908V5.63625C6.15976 5.82303 6.01303 5.96984 5.82617 5.96984V4.6355C6.01303 4.6355 6.15976 4.78222 6.15976 4.96908Z" fill="#575F64"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="11.3864" height="11.3864" fill="white" transform="translate(0.133179 0.610474)"/>
            </clipPath>
        </defs>
    </svg>

)

export default OTPLockSvg;
