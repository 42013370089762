import React from 'react';

const EmailSvg = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="white"/>
        <path d="M43.3332 22C43.3332 20.5334 42.1332 19.3334 40.6665 19.3334H19.3332C17.8665 19.3334 16.6665 20.5334 16.6665 22V38C16.6665 39.4667 17.8665 40.6667 19.3332 40.6667H40.6665C42.1332 40.6667 43.3332 39.4667 43.3332 38V22ZM40.6665 22L29.9998 28.6534L19.3332 22H40.6665ZM40.6665 38H19.3332V24.6667L29.9998 31.3334L40.6665 24.6667V38Z" fill="#010C15" fillOpacity="0.2"/>
    </svg>
);

const EmailSvgActive = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#F6FBFF"/>
        <path d="M43.3332 22C43.3332 20.5333 42.1332 19.3333 40.6665 19.3333H19.3332C17.8665 19.3333 16.6665 20.5333 16.6665 22V38C16.6665 39.4667 17.8665 40.6667 19.3332 40.6667H40.6665C42.1332 40.6667 43.3332 39.4667 43.3332 38V22ZM40.6665 22L29.9998 28.6533L19.3332 22H40.6665ZM40.6665 38H19.3332V24.6667L29.9998 31.3333L40.6665 24.6667V38Z" fill="#439ADE"/>
    </svg>
)

export { EmailSvg, EmailSvgActive };