import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DataContext } from '../Context/dataContext';
// import LoginLanding from '../Components/LoginLanding';
import Locksvg from '../SVGs/locksvg';
import CloseBlackIcon from '../SVGs/closeBlackIcon';
import LoginBar from '../Components/LoginBar'
import Label from '../Components/common/Label'
import FormInput from '../Components/common/FormInput'
import ConfirmedSvg from '../SVGs/ConfirmedSvg';
import StudentLogin from './StudentLogin';
import ParentLogin from './ParentLogin';
// import EducatorLogin from './EducatorLogin';
import { motion } from "framer-motion";
// import EducatorLoginWOA from '../DummyPages/EducatorLoginWOA';
import EducatorLogin from './EducatorLogin';
import { FetchContext } from '../Context/fetchContext';
import LoadingModal from '../Components/LoadingModal';
import FormError from '../Components/FormError';
import { toTitleCase } from '../Utils/titleCase';

const NewPwrdForm = ({ token, email }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const history = useHistory();
    const dataContext = useContext(DataContext);
    const fetchContext = useContext(FetchContext);

    const LoginSchema = Yup.object().shape({
        newPassword: Yup.string().required('Kindly enter a new password to proceed'),
        reEnteredPassword: Yup.string().required('Kindly re-enter same password')
    });

    const handleCloseModal = () => {
        dataContext.setShowFPswrdModal(false); 
        history.push('/')
        // portal === 'educator' ? history.push('/') : history.push(`/${portal}s`); 
        return null
    };

    const submitCredentials = async (credentials) => {
        const body = {
            token,
            password : credentials.newPassword,
            confirmpassword : credentials.reEnteredPassword,
            SchoolUrl: window.location.host.includes("localhost") ? 'demo.edves.net' : window.location.host,
            username: email
        }
        console.log(body)
        try {
          setLoading(true);
          const { data } = await fetchContext.postRoute(`auth/resetpassword`, body);
          console.log(data.data);
          if(data.status) {
            setLoading(false);
            dataContext.setShowNewPwrdForm(false);
            dataContext.setShowPswrdConfirmed(true);
            setError('');
          } else {
            setLoading(false);
            setError(data.message);
          }
        } catch (error) {
            setLoading(false);
            setError('Something Went Wrong');
            console.log(error);
        }
    }

    // const handleSavePswrd = () => {
    //     dataContext.setShowNewPwrdForm(false); 
    //     dataContext.setShowPswrdConfirmed(true);
    //     return null
    // }

    return (
        <>
            {loading && <LoadingModal />}
            <motion.div animate={{scale:1}} initial={{scale:0}} transition={{delay:0.5}} exit="exit" className='bg-white w-3/4 sm:w-1/3 rounded-lg p-6'>
                <div className='flex justify-end'>
                    <span onClick={() => handleCloseModal()} className='cursor-pointer'> <CloseBlackIcon /> </span>
                </div>
                <div className='flex flex-col items-center text-center px-4 py-4 justify-center'>
                    <div className=''>
                        <Locksvg />
                    </div>
                    <div className='text-headingDark font-bold mb-2 text-h2'>
                        Create new password
                    </div>
                    <div className='px-4 mb-1'>
                        <span className='text-headingDark opacity-70 font-bold text-p'>
                            Kindly enter a new password
                        </span>
                    </div>
                </div>

                <div className='px-4'>
                    <Formik
                    initialValues={{
                        newPassword: '',
                        reEnteredPassword: ''
                    }}
                    onSubmit={values =>
                        submitCredentials(values)
                    }
                    validationSchema={LoginSchema}
                    >
                        {() => (
                            <Form className='mt-4'>
                                {/* {loginSuccess && (
                                    <FormSuccess text={loginSuccess} />
                                )} */}
                                {error && (
                                    <div className='mb-4 w-full'>
                                        <FormError text={error} />
                                    </div>
                                )}
                                <div className='space-y-4'>
                                    <div className="">
                                        <div className="mb-1">
                                            <Label text="New Password" />
                                        </div>
                                        <FormInput
                                            ariaLabel="New Password"
                                            name="newPassword"
                                            type="password"
                                            placeholder="Enter your password"
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-1">
                                            <Label text="Re-enter Password" />
                                        </div>
                                        <FormInput
                                            ariaLabel="Confirm Password"
                                            name="reEnteredPassword"
                                            type="password"
                                            placeholder="Confirm your password"
                                        />
                                    </div>
                                    <div className='mt-4 py-2 w-full'>
                                        <LoginBar 
                                            loading={loading}
                                            savePwrd = {true}
                                            type='submit'
                                            className='bg-primary rounded-lg w-full primaryGradient transition-colors duration-300 ease-in-out cursor-pointer text-white text-p'
                                            text='Save Password' 
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* <div onClick={() => handleSavePswrd()} className='mt-4 py-2 px-4'>
                    <LoginBar className='bg-primary rounded-lg primaryGradient transition-colors duration-300 ease-in-out cursor-pointer text-white text-p' text='Save Password' />
                </div> */}
            </motion.div>
        </>
    )
}

const PasswordConfirmed = ({portal, tokenStatus}) => {
    const history = useHistory();
    const dataContext = useContext(DataContext);

    const handleCloseModal = () => {
        dataContext.setShowFPswrdModal(false); 
        history.push('/')
        // portal === 'educator' ? history.push('/') : history.push(`/${portal}s`); 
        return null
    };

    return (
    <>
        <div className='bg-white w-3/4 sm:w-1/3 rounded-lg p-6'>
            <div className='flex justify-end'>
                <span onClick={() => handleCloseModal()} className='cursor-pointer'> <CloseBlackIcon /> </span>
            </div>
            <div className='flex flex-col items-center text-center px-4 py-4 justify-center w-full'>
                <div className=''>
                    {tokenStatus ? 
                    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.3333 21.3333H2.66667V2.66667H21.3333V21.3333ZM0 0V24H24V0H0ZM18.6667 16.7867L16.7867 18.6667L12 13.88L7.21333 18.6667L5.33333 16.7867L10.12 12L5.33333 7.21333L7.21333 5.33333L12 10.12L16.7867 5.33333L18.6667 7.21333L13.88 12L18.6667 16.7867Z" fill="red"/>
                    </svg>
                    :
                    <ConfirmedSvg />}
                </div>
                <div className={`${tokenStatus ? 'text-errorColor' : 'text-headingDark'} font-bold mb-2 text-h2`}>
                    {tokenStatus ? toTitleCase(tokenStatus) : 'Password confirmed'}
                </div>
                <div className='px-4 pb-4 mb-3'>
                    <span className={` opacity-70 font-bold text-p`}>
                        {tokenStatus === 'invalid' ? 'Looks like the reset password link that brought you here is now invalid, please check your email for a more recent one' : tokenStatus === 'expired' ? 'Please reinitiate password reset as your last session is expired' : 'We have saved your new password, you can proceed to login'}
                    </span>
                </div>
                <div onClick={handleCloseModal} className='w-full'>
                    <LoginBar className='bg-primary rounded-lg cursor-pointer text-white w-full text-p' text='Log in' />
                </div>
            </div>
        </div>
    </>
)}

const CreateNewPwrd = () => {
    const dataContext = useContext(DataContext);
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[[]]/g, '$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const token = getParameterByName('token');
    const portal = getParameterByName('usertype');
    const email = getParameterByName('email');
    // const status = getParameterByName('status')
    const tokenStatus = getParameterByName('status')

    return (
        <>
            { (tokenStatus === 'invalid' || tokenStatus === 'expired') &&
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-20'>
                        <PasswordConfirmed
                            tokenStatus = {tokenStatus}
                        />
                    </div>
                    <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            { dataContext.showPswrdConfirmed && 
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-20'>
                        <PasswordConfirmed />
                    </div>
                    <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            { dataContext.showNewPwrdForm && !tokenStatus &&
                <>
                    <div layout className='flex justify-center items-center fixed inset-0 z-20'>
                        <NewPwrdForm
                            token = {token} 
                            email = {email}
                            portal = {portal}
                        />
                    </div>
                    <>
                        <motion.div>
                            <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                        </motion.div>
                    </>
                </>
            }
            {   portal === 'student' ? <StudentLogin /> :
                portal === 'parent' ? <ParentLogin /> : <EducatorLogin />
            }
        </>
    );
}

export { CreateNewPwrd, PasswordConfirmed };