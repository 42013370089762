import React from 'react';
// import { Link } from 'react-router-dom';

const Hyperlink = ({ text, to, onClick }) => (
  <p
    // to={to}
    onClick={onClick}
    className="font-normal text-white text-opacity-90 sm:text-opacity-100 lg:text-primary hover:text-blue-500 focus:outline-none cursor-pointer focus:underline transition ease-in-out duration-150"
  >
    {text}
  </p>
);

export default Hyperlink;
