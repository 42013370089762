import React from "react";
import { Google, Apple } from "../../SVGs/Icons";
import ReactGA from "react-ga";

const GoogleIcon = (userHost) => (
  <ReactGA.OutboundLink
    eventLabel="Download mobile app android"
    to={`${
      userHost?.userHost === "enugustate.edves.net"
        ? "https://play.google.com/store/apps/details?id=net.edves.android.ministry_of_education_enugu_state"
        : "https://play.google.com/store/apps/details?id=net.edves.android"
    }`}
    target="_blank"
    rel="noreferrer"
  >
    <div className="flex rounded-lg w-30  lg:w-40 border border-headingDark border-opacity-10 cursor-pointer bg-secondary">
      <div className="w-1/3 flex justify-center items-center">
        <Google />
      </div>
      <div className="py-0.5">
        <div className="flex flex-col">
          <span className="text-xs space-y-0.5 uppercase"> Get it on </span>
          <span className="text-sm font-semibold"> Google Play </span>
        </div>
      </div>
    </div>
  </ReactGA.OutboundLink>
);

const AppleIcon = (userHost) => (
  <ReactGA.OutboundLink
    eventLabel="Download mobile app apple"
    to={`${
      userHost?.userHost === "enugustate.edves.net"
        ? "https://apps.apple.com/us/app/enugu-state-model-schools/id6472432182"
        : "https://apps.apple.com/us/app/edves/id6444702319"
    }`}
    // to="https://apps.apple.com/us/app/edves/id6444702319"
    target="_blank"
    rel="noreferrer"
  >
    <div className="flex rounded-lg w-40 h-12 border border-headingDark border-opacity-10 cursor-pointer bg-secondary">
      <div className="w-1/3 flex justify-center items-center">
        {" "}
        <Apple />{" "}
      </div>
      <div className="flex flex-col w-2/3 py-1">
        <span className="text-xs space-y-1"> Download on the </span>
        <span className="text-p font-semibold"> Apple Store </span>
      </div>
    </div>
  </ReactGA.OutboundLink>
);

export { GoogleIcon, AppleIcon };
