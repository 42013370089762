import './App.css';

// components import
import { DataProvider} from './Context/dataContext';
import { FetchProvider} from './Context/fetchContext';
// import StudentLogin from './Pages/StudentLogin';
// import ParentLogin from './Pages/ParentLogin';
import EducatorLogin from './Pages/EducatorLogin';
import { CreateNewPwrd } from './Pages/NewPassword';

// external libraries import
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

function App() {

  const history = createBrowserHistory();
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname + window.location.search); // Record a pageview for the given page
  });

  return (
    <Router history={history} basename={'/login'}>
      <FetchProvider>
        <DataProvider>
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route exact path = '/'>
                <EducatorLogin />
              </Route>
              <Route path = '/create-new-password'>
                <CreateNewPwrd />
              </Route>
              {/* <Route path = '/students'>
                <StudentLogin />
              </Route> */}
              {/* <Route path = '/parents'>
                <ParentLogin />
              </Route> */}
            </Switch>

          </AnimatePresence>
        </DataProvider>
      </FetchProvider>
    </Router>
  );
}

export default App;
