import React, { useContext } from 'react';
import LoginLanding from '../Components/LoginLanding';
import { DataContext } from '../Context/dataContext';
import studentImg from '../images/studentImg.png';
import { ForgotPswrdModal } from './ForgotPassword';

const StudentLogin = () => {
    const dataContext = useContext(DataContext)
    return (
        <>
            { (dataContext.fPswrdComponent === 'student' || dataContext.fPswrdComponent === '')  && <ForgotPswrdModal />}
            <LoginLanding
                HeadingText = 'Access all your class resources and learning materials on the go'
                person = 'student'
                subHeadingHighlight = 'Students'
                subHeadingText = 'can use Edves to experience interactive & seamless learning on the go, gain access to class assignment, materials, take computer- based tests and exam...'
                img = {studentImg}
                loginBar1 = 'Log in as a Student'
                loginBar2 = 'Log in as a School Staff'
                loginBar3 = 'Log in as a Parent'
            />
        </>
    )
}

export default StudentLogin;