import React, { useContext } from 'react';
import enterIcon from '../../images/enterIcon.png';
import { motion } from 'framer-motion';
// import EdvesSvg from '../../SVGs/edvesSvg';
import WhiteEdvesLogo from '../../SVGs/WhiteEdvesLogo';
import { FetchContext } from '../../Context/fetchContext';
import sanwoOlu from '../../images/sanwoOlu.jpg'

const ImageSection = ({headingText, subHeadingText, subHeadingHighlight, img}) => {
    const fetchContext = useContext(FetchContext);
    const host = fetchContext.host;

    const Heading = () => (
        <h1 className='font-bold text-h1'> {headingText} </h1>
    );
    
    const SubHeading = () => (
        <div className='text-p font-normal w-full sm:w-3/4'> 
            <span className='text-lg font-bold'>
                {subHeadingHighlight}
            </span> {subHeadingText} 
        </div>
    );

    const iconVariants = {
        hidden: {
            opacity: 0,
            scale: 0
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 2
            },
            scale: [1, 1.5, 1, 1.5, 1]
        }
    }
    return (
        <motion.div  
            animate={{ opacity:1 }} 
            initial={{ opacity:0 }} 
            transition={{ duration:.1 }} 
            className='h-screen w-full relative'
        >
            { host !== 'lasgeduhub.edves.net' ? 
            <span className='flex-shrink w-full'>
                <img src={img} alt="relevantPic" className='relative bg-clip-content w-full h-full' />   
            </span> :
            <span className='flex-shrink w-full'>
                <img src={sanwoOlu} alt="relevantPic" className='absolute bg-cover inset-0 w-full h-full' />   
                <div className = 'inset-0 absolute w-full h-full bg-primary bg-opacity-70'></div>
                <div className = 'inset-0 absolute w-full h-full bg-headingDark bg-opacity-40'></div>
            </span>
            }
            <div className='absolute inset-0 text-white w-3/4'>
                <div className={`${host === 'lasgeduhub.edves.net' ? 'mt-40' : 'mt-40'} mx-12 w-full`}>
                {/* <div className='mt-32 mx-12 w-full'> */}
                    <Heading />
                    <div className='mt-8 w-full space-x-3 flex items-start'>
                        <motion.span variants={iconVariants} className='mt-1 flex-shrink-0'>
                            <img src={enterIcon} alt='enterIcon' />
                        </motion.span>
                        <SubHeading />
                    </div>
                </div>
            </div>
            {/* <div className='bottom-4 left-12 right-60 absolute text-white text-p font-bold'>  */}
            <div className='absolute bottom-5 left-12 text-white text-p font-bold'> 
                <div className='flex space-x-1'>
                    <span> Powered by </span >
                    <span  className='mt-1'> <WhiteEdvesLogo />  </span>
                </div>
            </div>
        </motion.div>
    ) //bg-cover object-contain
}

export default ImageSection;