import React from "react";
import loadingSpin from '../images/loadingSpin.gif';

export default function LoadingModal() {
  return (
    <>
        <>
          <div
            className="w-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative my-6">
              <div className='w-64 h-48 flex justify-center rounded-lg items-center shadow-lg bg-white'>
                <img src={loadingSpin} alt='loading...' />
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    </>
  );
}