import React, { useContext } from 'react';
import LoginLanding from '../Components/LoginLanding';
import { DataContext } from '../Context/dataContext';
import parentImg from '../images/parentImg.png';
import { ForgotPswrdModal } from './ForgotPassword';

const ParentLogin = () => {
    const dataContext = useContext(DataContext)
    return (
        <>
            { (dataContext.fPswrdComponent === 'parent' || dataContext.fPswrdComponent === '')  && <ForgotPswrdModal />}
            <LoginLanding
                HeadingText = 'Get daily reports on your child’s performance, and learning progress'
                subHeadingText = 'can use Edves to maintain real- time conversations with the school on the conducts and academic progress of their wards, pay school fees, track reports and assignment...'
                subHeadingHighlight = 'Parents'
                img = {parentImg}
                person = 'parent'
                loginBar1 = 'Log in as a Parent'
                loginBar2 = 'Log in as a School Staff'
                loginBar3 = 'Log in as a Student'
            />
        </>
    )
}

export default ParentLogin;