import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DataContext } from '../Context/dataContext';
import CloseBlackIcon from '../SVGs/closeBlackIcon';
import LoginBar from '../Components/LoginBar'
import Label from '../Components/common/Label'
import FormInput from '../Components/common/FormInput'
import ConfirmedSvg from '../SVGs/ConfirmedSvg';
import OTPLockSvg from '../SVGs/OTPLockSvg';
import { FetchContext } from '../Context/fetchContext';
import LoadingModal from '../Components/LoadingModal';
import FormError from '../Components/FormError';
import FormSuccess from '../Components/FormSuccess';
import ReactGA from 'react-ga';

const OTPForm = ({ setShowEPSelect, successMessage, send, resendSuccess, setResendSuccess, usertype }) => {
    const history = useHistory();
    const dataContext = useContext(DataContext);
    const fetchContext = useContext(FetchContext);    

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ timer, setTimer ] = useState(true);

    const OTPSchema = Yup.object().shape({
        otp: Yup.string().required('Kindly input OTP'),
    });

    const username = dataContext.credentials && dataContext.credentials.username


    const formatSuccessMsg = () => {
        const phoneNum = successMessage && successMessage.split(' ')[6]
        const firstPart = phoneNum && phoneNum.substring(0, 4)
        const lastPart = phoneNum && phoneNum.substring(phoneNum.length -4)
        const hashedNumber = `${firstPart} *** ${lastPart}`
        return hashedNumber
    }

    const handleCloseModal = () => {
        setShowEPSelect(true);
        dataContext.setShowOTPForm(false);
        dataContext.setFPswrdComponent(null); 
        dataContext.setShowOTPConfirmed(false);
        setResendSuccess(false);
        history.push('/'); 
        return null
    };

    // const handleSavePswrd = () => {
    //     dataContext.setShowOTPConfirmed(true);
    // };

    const submitCredentials = async (credentials) => {
        const body = {
            otp: credentials.otp,
            usertype,
            username,
            SchoolUrl: fetchContext.host
        }
        console.log(body)
        try {
          setLoading(true);
          const { data } = await fetchContext.postRoute(`auth/verifyotp`, body);
          console.log(data.data);
          if(data.status) {
            setLoading(false);
            dataContext.setShowOTPConfirmed(true);
            //   setSuccess(data.message);
              setError('');
          } else {
              setError(data.message);
              setLoading(false);
          }
        } catch (error) {
            setLoading(false);
            setError('Something Went Wrong');
            console.log(error);
        }
    }

    const handleResend = () => {
        ReactGA.event({
            category: 'User',
            action: 'Resend password via email'
        });

        send(
            'forgetpassword', 
            {
                username: dataContext.credentials && dataContext.credentials.username,
                usertype: dataContext.fPswrdComponent,  // Remember to make dynamic, use dataContext.fPswrdComponent
                SchoolUrl: fetchContext.host,
                medium : 'sms'
            },
            null,
            { resend : true }
        );
    }

    const successMsgTimeout = () => {
        setTimeout(() => {
            setTimer(false)
        }, 3000);

        return timer && <FormSuccess text={'Resent Successfully'} />
    }

    return (
        <> {loading && <LoadingModal />}
            <div className='bg-white w-3/4 sm:w-1/3 rounded-lg p-4 sm:p-6'>
                <div className='flex justify-end'>
                    <span onClick={() => handleCloseModal()} className='cursor-pointer p-3 flex items-center justify-center hover:bg-lightBlue transition-colors duration-200 ease-in-out rounded-full'> 
                        <CloseBlackIcon /> 
                    </span>
                </div>
                <div className='flex flex-col items-center text-center px-4 sm:py-4 justify-center'>
                    <div className='mb-2'>
                        <OTPLockSvg />
                    </div>
                    <div className='text-headingDark font-bold mb-2 text-lg sm:text-h2'>
                        Enter OTP
                    </div>
                    <div className='p-1 sm:px-4 sm:mb-1'>
                        <span className='text-headingDark opacity-70 font-semibold text-sm sm:text-p'>
                            Kindly enter the OTP we sent to your registered phone number <span className='opacity-100 font-bold'> {successMessage && formatSuccessMsg()} </span>
                        </span>
                    </div>
                </div>

                <div className='mt-4 px-4'>
                    <Formik
                    initialValues={{
                        otp: '',
                    }}
                    onSubmit={values =>
                        submitCredentials(values)
                    }
                    validationSchema={OTPSchema}
                    >
                        {() => (
                            <Form className='sm:mt-0'>
                                {resendSuccess && (
                                    successMsgTimeout()
                                )}
                                {error && (
                                    <div className='mb-4 w-full'>
                                        <FormError text={error} />
                                    </div>
                                )}
                                <div className='space-y-4'>
                                    <div className="">
                                        <div className="mb-1">
                                            <Label text="OTP" modal = {true} />
                                        </div>
                                        <FormInput
                                            ariaLabel="Enter OTP"
                                            name="otp"
                                            type="text"
                                            placeholder="Enter OTP code"
                                        />
                                    </div>
                                </div>
                                <div className='text-headingDark mt-6 text-center font-bold text-xs'>
                                    I did not get the code,
                                    <span onClick={() => handleResend()} className='text-primary cursor-pointer'> Resend </span> or contact school admin
                                </div>
                                <div className='mt-4 py-2 w-full'>
                                    <LoginBar 
                                        className='bg-primary rounded-lg cursor-pointer primaryGradient text-white text-p w-full' 
                                        text='Save Password' 
                                        type='submit'
                                        // handleBtnClick={handleSavePswrd}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

const OTPConfirmed = ({ setShowEPSelect }) => {
    const history = useHistory();
    const dataContext = useContext(DataContext);

    const handleCloseModal = () => {
        setShowEPSelect(true);
        dataContext.setShowOTPForm(false)
        dataContext.setFPswrdComponent(null); 
        // dataContext.setShowFPswrdModal(false); 
        dataContext.setShowOTPConfirmed(false)        
        history.push('/'); 
        return null
    };

    return (
    <>
        <div className='bg-white w-3/4 sm:w-1/3 rounded-lg p-6'>
            <div className='flex justify-end'>
                <span onClick={() => handleCloseModal()} className='cursor-pointer'> <CloseBlackIcon /> </span>
            </div>
            <div className='flex flex-col items-center text-center px-4 py-4 justify-center w-full'>
                <div className=''>
                    <ConfirmedSvg />
                </div>
                <div className='text-headingDark font-bold mb-2 text-h2'>
                    Authentification confirmed
                </div>
                <div className='px-4 pb-4 mb-3'>
                    <span className='text-headingDark opacity-70 font-bold text-p'>
                        You can now proceed to the dashboard
                    </span>
                </div>
                <div onClick={() => {history.push('/create-new-password')}} className='w-full'> 
                    <LoginBar 
                        className='bg-primary rounded-lg cursor-pointer primaryGradient text-white w-full text-p' 
                        text='Proceed' 
                    />
                </div>
            </div>
        </div>
    </>
)}

const ConfirmOtp = ({ setShowEPSelect, successMessage, loading, send, resendSuccess, setResendSuccess, usertype }) => {
    const dataContext = useContext(DataContext)

    return (
        <>
            {loading && <LoadingModal />}
            { !dataContext.showOTPConfirmed && dataContext.showConfirmOTP &&
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-30'>
                        <OTPForm 
                            setShowEPSelect= {setShowEPSelect} 
                            successMessage= {successMessage} 
                            send={send}
                            resendSuccess = {resendSuccess}
                            setResendSuccess = {setResendSuccess}
                            usertype = {usertype}
                        />
                    </div>
                    <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            { dataContext.showOTPConfirmed && 
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-30'>
                        <OTPConfirmed setShowEPSelect={setShowEPSelect} />
                    </div>
                    <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            {/* {   dataContext.fPswrdComponent === 'students' ? <StudentLogin /> :
                dataContext.fPswrdComponent === 'parents' ? <ParentLogin /> : <EducatorLogin />
            } */}
        </>
    );
}

export { ConfirmOtp, OTPConfirmed };