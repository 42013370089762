import React from 'react';
import { useField } from 'formik';
import ErrorMsg from './ErrorMsg';
import Input from './Input';

const FormInput = ({
  ariaLabel,
  name,
  type,
  placeholder,
  usernameIsEmpty,
  isUsernameInputed
}) => {
  const [field, meta] = useField(name);

  const showErr = () => {
    return (
      <ErrorMsg text={meta.error}></ErrorMsg>
    )
  }
  // console.log(usernameIsEmpty)

  return (
    <>
      <Input
        field={field}
        ariaLabel={ariaLabel}
        name={field.name}
        type={type}
        placeholder={placeholder}
        isUsernameInputed = {isUsernameInputed}
      />
      {(meta.touched && meta.error) || (usernameIsEmpty) ? showErr() : null}
      {/* {meta.touched && meta.error ? (
        <ErrorMsg text={meta.error}></ErrorMsg>
      ) : null} */}
    </>
  );
};

export default FormInput;
