import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DataContext } from "../Context/dataContext";

import FormInput from "./common/FormInput";
// import FormSuccess from './common/FormSuccess';
import FormError from "./FormError";
import Label from "./common/Label";
import Hyperlink from "./common/Hyperlink";
import { FetchContext } from "../Context/fetchContext";
import FormSuccess from "./FormSuccess";
import LoginBar from "./LoginBar";
import ReactGA from "react-ga";

const LoginForm = ({ educator, person, barText, loginBarClasses }) => {
  const fetchContext = useContext(FetchContext);
  const dataContext = useContext(DataContext);
  const LoginSchema = Yup.object().shape({
    admissionNumber: Yup.string().required(
      educator
        ? "Kindly enter your username"
        : "Kindly enter your admission number or email to proceed"
    ),
    password: Yup.string().required("Kindly input password"),
  });

  const [loginSuccess, setLoginSuccess] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(null);
  const [usernameIsEmpty, setUsernameIsEmpty] = useState(false);
  const [isUsernameInputed, setIsUsernameInputed] = useState(false);

  let host = fetchContext.host;
  const currPortal = window.location.pathname.split("/")[2];
  const usertype = currPortal === "" ? "educator" : `${currPortal}`;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
      const TRACKING_ID = "UA-23405765-9"; // TRACKING_ID
      ReactGA.initialize(TRACKING_ID);
    };
  });

  const submitCredentials = async (credentials) => {
    let fullCredentials;
    if (usertype === "educator" || usertype === "parents") {
      fullCredentials = {
        username: credentials.admissionNumber,
        password: credentials.password,
        SchoolUrl: host,
      };
    } else {
      fullCredentials = {
        admissionnumber: credentials.admissionNumber,
        password: credentials.password,
        SchoolUrl: host,
      };
    }
    // console.log(fullCredentials);
    try {
      setLoginLoading(true);
      const { data } = await fetchContext.postRoute(
        `auth/${usertype === "" ? "educator" : usertype}/login`,
        fullCredentials
      );
      //   console.log(data);
      if (data.status) {
        setLoginLoading(false);
        setLoginSuccess(data.message);
        setLoginError("");
        localStorage.setItem("amData", JSON.stringify(data.data));
        // redirect after successful login
        setTimeout(() => {
          let currentUrl = window.location.host;
          if(currentUrl.includes('enems.enugustate.gov.ng')){
            host = "enems.enugustate.gov.ng";
          }
          // if (host === "enugustate.edves.net") {
           
          //   host = "enems.enugustate.gov.ng";
          // }
          if (person === "school staff") {
            window.location.assign(
              `https://${host}/_prologin.php?userid=${credentials.admissionNumber}&token=${data.data[0].token}`
            );
          } else if (person === "student") {
            window.location.assign(`https://${host}/${person}s/locker`);
          } else {
            window.location.assign(
              `https://basic.edves.net/new_auth.php?AdmissionNumber=${data.data[0].admissionnumber}&token=${data.data[0].token}&schoolID=${data.data[0].schoolUniqueId}&schoolUrl=${host}`
            );
          }
        }, 700);
      } else {
        setLoginError(data.message);
        setLoginLoading(false);
      }
    } catch (error) {
      setLoginLoading(false);

      !window.navigator.onLine
        ? setLoginError("Appears you are offline, check internet connectivity")
        : setLoginError(
            error?.response?.data?.message ||
              "Something Went Wrong, please try again later or contact school admin"
          );
      //  console.log(error);
    }
  };

  const handleFPwrd = (touched) => {
    !touched
      ? setIsUsernameInputed(true)
      : !usernameIsEmpty && dataContext.setFPswrdComponent(person);
  };

  // console.log(usernameIsEmpty)

  return (
    <>
      <Formik
        initialValues={{
          admissionNumber: "",
          password: "",
        }}
        onSubmit={(values) => submitCredentials(values)}
        validationSchema={LoginSchema}
        validate={(values) => {
          if (!values.admissionNumber) {
            // console.log(!values.admissionNumber)
            setUsernameIsEmpty(true);
          } else {
            setUsernameIsEmpty(false);
            setIsUsernameInputed(false);
            dataContext.setCredentials({
              username: values.admissionNumber,
              schoolUrl: host,
            });
            // console.log(values)
          }
          return {};
        }}
      >
        {({ errors, touched }) => (
          <Form className="mt-0">
            {loginSuccess && <FormSuccess text={loginSuccess} />}
            {loginError && <FormError text={loginError} />}
            {isUsernameInputed && (
              // <FormError text='Please input username or email address' />
              <FormError text="Please input username" />
            )}
            <div className="space-y-4">
              <div className="">
                <div className="mb-1 mx-auto w-full md:w-3/4 lg:w-full">
                  <Label text={educator ? "Username" : "Admission Number"} />
                </div>
                <FormInput
                  ariaLabel="AdmissionNumber"
                  name="admissionNumber"
                  type="text"
                  placeholder="edves/20210402/Edves"
                  usernameIsEmpty={usernameIsEmpty}
                  isUsernameInputed={isUsernameInputed}
                />
              </div>
              <div>
                <div className="flex justify-between mx-auto w-full md:w-3/4 lg:w-full">
                  <div className="mb-1">
                    <Label text="Password" />
                  </div>
                  <div
                    onClick={() => {
                      handleFPwrd(touched.admissionNumber);
                      ReactGA.modalview("Forgot password button");
                    }}
                    className="text-sm leading-5"
                  >
                    <Hyperlink
                      // to="forgot-password"
                      text="Forgotten Password?"
                    />
                  </div>
                </div>
                <FormInput
                  ariaLabel="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              <div className="md:w-3/4 md:mx-auto lg:w-full">
                <LoginBar
                  loading={loginLoading}
                  type="submit"
                  className={loginBarClasses}
                  text={barText}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
