import React, { useContext } from 'react';
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FetchContext } from '../Context/fetchContext';

const LoginBar = ({className, text, type, handleBtnClick, loading, icon, opacity, savePwrd}) => {
    const history = useHistory();
    const fetchContext = useContext(FetchContext);
    const host = fetchContext.host

    const textStyles = classnames({
        'text-center w-full font-bold': true,
        'opacity-70' : opacity
    });

    const handleClick = () => {
        switch (text) {
            case 'Log in as a Student':
                return window.location.assign(`https://${host}/students`)
            //   return history.push('students');
            case 'Log in as a Parent':
                return window.location.assign(`https://${host}/parent`)
                // return history.push('parents');
            case 'Log in as a School Staff':
                return history.push('');
            default:
                return handleBtnClick && handleBtnClick()
        }
    }

    return (
        <button onClick = {() => handleClick()} type={type} className={className}>
                {/* <ReactGA.OutboundLink eventLabel={text}> */}
                { loading ? (
                    <div className='flex justify-center text-center py-2 px-4'>
                        <span className="flex items-center">
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                            <span className="ml-0 sm:ml-2 font-bold"> {savePwrd ? 'Saving...' : 'logging in...'} </span>
                        </span>
                    </div>
                     ) :
                    <div className='flex text-center py-2 px-4'>
                        { icon &&
                            <span className=''>
                            <img src={icon} alt='loginIcon' />
                        </span>}
                        <span className={textStyles}> {text} </span>
                    </div>
                }
        {/* </ReactGA.OutboundLink> */}
            </button>
    )
}

export default LoginBar;