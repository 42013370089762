import React from 'react';

const locksvg = () => (
    <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M49.1835 19.1835V30H40.4329V19.1835C40.4329 13.4294 35.7529 8.75059 30.0011 8.75059H29.9999C24.2458 8.75176 19.567 13.4294 19.567 19.1835V30H10.8164V19.1835C10.8164 8.60471 19.4211 0.00117647 29.9999 0H30.0011C40.5776 0 49.1835 8.60471 49.1835 19.1835Z" fill="#D7DEED"/>
        <path d="M49.1835 19.1835V30H40.4329V19.1835C40.4329 13.4294 35.7529 8.75059 30.0012 8.75059H30V0H30.0012C40.5776 0 49.1835 8.60471 49.1835 19.1835Z" fill="#B6CBDE"/>
        <path d="M56.1249 30.3082V56.2494C56.1249 58.3212 54.4461 60 52.3755 60H7.6249C5.55431 60 3.87549 58.3212 3.87549 56.2494V30.3082C3.87549 28.2377 5.55431 26.5588 7.6249 26.5588H52.3755C54.4461 26.5588 56.1249 28.2377 56.1249 30.3082Z" fill="#266299"/>
        <path d="M56.1247 30.3082V56.2494C56.1247 58.3212 54.4459 60 52.3753 60H30V26.5588H52.3753C54.4459 26.5588 56.1247 28.2377 56.1247 30.3082Z" fill="#236EA5"/>
        <path d="M31.9246 44.5C31.8293 44.5306 31.7646 44.6118 31.7646 44.7012V46.1765C31.7646 47.1518 30.974 47.9412 29.9999 47.9412C29.0258 47.9412 28.2352 47.1518 28.2352 46.1765V44.7012C28.2352 43.3906 28.9175 42.2035 29.9999 41.5306C30.2552 41.3718 30.5328 41.2412 30.8281 41.1447C31.9881 40.7659 32.7399 39.6459 32.6552 38.4212C32.5612 37.059 31.42 35.9518 29.9999 35.9518C28.5345 35.9518 27.3375 37.1361 27.3375 38.6141C27.3375 39.5883 26.5481 40.3788 25.5728 40.3788C24.5987 40.3788 23.8081 39.5883 23.8081 38.6141C23.8081 35.2677 26.5155 32.4172 29.9999 32.4224C33.2722 32.4224 35.9552 34.9806 36.1763 38.1788C36.3716 41.0177 34.6234 43.6177 31.9246 44.5Z" fill="#D7DEED"/>
        <path d="M31.9247 44.5C31.8294 44.5306 31.7647 44.6118 31.7647 44.7012V46.1765C31.7647 47.1518 30.9741 47.9412 30 47.9412V41.5306C30.2553 41.3718 30.5329 41.2412 30.8282 41.1447C31.9882 40.7659 32.74 39.6459 32.6553 38.4212C32.5613 37.059 31.4201 35.9518 30 35.9518V32.4224C33.2723 32.4224 35.9553 34.9806 36.1765 38.1788C36.3718 41.0177 34.6235 43.6177 31.9247 44.5Z" fill="#B6CBDE"/>
        <path d="M31.7643 52.2682V52.4165C31.7643 53.3906 30.9737 54.1812 29.9996 54.1812C29.0255 54.1812 28.2349 53.3906 28.2349 52.4165V52.2682C28.2349 51.2941 29.0255 50.5035 29.9996 50.5035C30.9737 50.5035 31.7643 51.2941 31.7643 52.2682Z" fill="#D7DEED"/>
        <path d="M31.7647 52.2682V52.4165C31.7647 53.3906 30.9741 54.1812 30 54.1812V50.5035C30.9741 50.5035 31.7647 51.2941 31.7647 52.2682Z" fill="#B6CBDE"/>
        </g>
        <defs>
        <clipPath id="clip0">
            <rect width="60" height="60" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)

export default locksvg;