import React, { useState } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import Closesvg from '../../SVGs/closeSvg'

const Input = ({ ariaLabel, name, type, placeholder, isUsernameInputed }) => {
  const [field, meta] = useField(name);
  const [visibility, setVisibility] = useState(false);

  const togglePswrdVisibility = () => {
    setVisibility(!visibility)
  }

  const filterPasswordInput = () => {
    let filteredType;
    if (type !== 'password') {
      return filteredType = type
    } else {
      filteredType = visibility ? 'text' : 'password'
    }
    return filteredType
  }

  const inputClasses = classnames({
    "appearance-none relative block w-full md:w-3/4 lg:w-full mx-auto px-3 py-2 bg-defaultGray border placeholder-opacity-20  placeholder-font-semibold text-headingDark rounded-md focus:outline-none focus:border-primary focus:z-0 sm:text-sm sm:leading-5" : true,
    "border-errorColor" : (meta.touched && meta.error) || isUsernameInputed,
  })
// focus:shadow-outline-blue
  return (
    <div className='flex space-x-3 items-center relative'>
      <input
        {...field}
        aria-label={ariaLabel}
        name={name}
        type={filterPasswordInput()}
        className= {inputClasses}
        placeholder={placeholder}
      />
      {type === 'password' && (meta.touched || !meta.touched) && meta.error === undefined &&
        <span onClick={togglePswrdVisibility} className='absolute top-2 right-4 md:right-28 lg:right-4 z-20 text-headingDark text-opacity-70 text-sm lg:text-p cursor-pointer'> 
          {visibility ? 'Hide' : 'Show'} 
        </span>
      }
      {meta.touched && meta.error ? <Closesvg /> : null}
    </div>

)};

export default Input;
