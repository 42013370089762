import React, { useContext, useState } from 'react';
// import { useHistory } from 'react-router-dom'
import CloseBlackIcon from '../SVGs/closeBlackIcon';
import Locksvg from '../SVGs/locksvg';
import { EmailSvg, EmailSvgActive } from '../SVGs/EmailSvg';
import { InactiveRadioBtnSvg, ActiveRadioBtn } from '../SVGs/radioBtn';
import { InactivePhoneSvg, ActivePhoneSvg } from '../SVGs/phoneSvg';
import { DataContext } from '../Context/dataContext';
// import EducatorLogin from './EducatorLogin';
import { motion } from 'framer-motion';
import MailSentSvg from '../SVGs/mailSentSvg';
import { FetchContext } from '../Context/fetchContext';
import LoadingModal from '../Components/LoadingModal'
import FormError from '../Components/FormError';
import { ConfirmOtp } from './ConfirmOtp';
import FormSuccess from '../Components/FormSuccess';
import { replaceChar } from '../Utils/ReplaceChar';
import ReactGA from 'react-ga';

const backDrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
}

const modalContentVariant = {
    hidden: {
        scale: 0
    },
    visible: {
        scale: 1
    },
    transition: {
        delay: 0.5
    }
}

const ForgotPassword = ({ setShowEPSelect, successMessage, send, loading, error, usertype }) => {
    const [ emailBtnClick, setEmailBtnClick ] = useState(false);
    const [ phoneBtnClick, setPhoneBtnClick ] = useState(false);

    const fetchContext = useContext(FetchContext);
    const dataContext = useContext(DataContext);
    const username = dataContext.credentials && dataContext.credentials.username;

    const handleCloseModal = () => {
        dataContext.setFPswrdComponent(null); 
        return null
    };

    const handleEmailBtnClick = () => {
        ReactGA.modalview('Recover password with email');
        setPhoneBtnClick(false);
        setEmailBtnClick(true);

        // setTimeout(() => {
        //     setShowEPSelect(false);
        //     dataContext.setShowPwrdResetNotif(true)
        // }, 1000);

        // Call Endpoint
        send(
            'forgetpassword', 
            {
                username: username && username,
                usertype, 
                SchoolUrl: fetchContext.host,
                medium : 'email' 
            },
            {
                // action1: dataContext.setFPswrdComponent(''),
                action1: () => setShowEPSelect(false),
                action2: () => dataContext.setShowPwrdResetNotif(true)
            }
        );
    };

    const handlePhoneBtnClick = () => {
        ReactGA.modalview('Recover password with sms');
        setPhoneBtnClick(true);
        setEmailBtnClick(false);

        // Call Endpoint
        send(
            'forgetpassword', 
            {
                username: username && username,
                usertype: usertype ? usertype : 'educator',  
                SchoolUrl: fetchContext.host,
                medium : 'sms'
            },
            {
                action1: () => setShowEPSelect(false),
                action2: () => dataContext.setShowOTPForm(true)
            }
        );
        // setTimeout(() => {
        //     setShowEPSelect(false)
        //     dataContext.setShowOTPForm(true);
        // }, 1000);
    }

    return (
        <> {loading && <LoadingModal />}
            <motion.div variants={modalContentVariant} initial="hidden" animate="visible" className='bg-white w-4/5 lg:w-1/3 rounded-lg p-4 lg:p-6'>
                <div className='flex justify-end'>
                    <span onClick={() => handleCloseModal()} className='cursor-pointer p-3 flex items-center justify-center hover:bg-lightBlue transition-colors duration-200 ease-in-out rounded-full'>
                        <CloseBlackIcon />
                    </span>
                </div>
                <div className='flex flex-col items-center text-center px-1 lg:px-4 py-4 justify-center'>
                    <div className='mb-1 lg:mb-0'>
                        <Locksvg />
                    </div>
                    <div className='text-headingDark font-bold mb-2 text-lg lg:text-h2'>
                        Forgotten Password?
                    </div>
                    <div className='hidden lg:block px-4 pb-4 mb-4'>
                        <span className='text-headingDark opacity-70 font-bold text-p'>
                        Kindly select where you would like to receive your password reset instructions
                        </span>
                    </div>
                    {error && (
                        <div className='mb-4 w-full'>
                            <FormError text={error} />
                        </div>
                    )}
                    <button onClick={() => handleEmailBtnClick()} className='bg-defaultGray border focus:border-primary cursor-pointer border-grayBorder rounded p-2 lg:p-4 mb-4 lg:mb-6 flex items-center justify-between'>
                        <div className='hidden lg:flex flex-shrink-0'>
                            {emailBtnClick ? <EmailSvgActive /> : <EmailSvg /> }
                        </div>
                        <div className='px-1 lg:px-4 lg:text-left text-center space-y-2'>
                            <div className='text-p text-headingDark font-bold lg:text-left text-opacity-70'> Reset via Email </div>
                            <div className='text-p opacity-70'> We will send a reset link to the email address you provided </div>
                        </div>
                        <div className='hidden lg:flex flex-shrink-0'>
                            {emailBtnClick ? <ActiveRadioBtn /> : <InactiveRadioBtnSvg />} 
                        </div>
                    </button>
                    <button onClick={handlePhoneBtnClick} className='bg-defaultGray border focus:border-primary cursor-pointer border-grayBorder rounded p-2 lg:p-4 flex items-center justify-between'>
                        <div className='hidden lg:flex flex-shrink-0'>
                            {phoneBtnClick ? <ActivePhoneSvg /> : <InactivePhoneSvg />}
                        </div>
                        <div className='px-1 lg:px-4  lg:text-left text-center space-y-2'>
                            <div className='text-p text-headingDark font-bold text-opacity-70'> Reset via SMS </div>
                            <div className='text-p opacity-70'> We will send a reset link to the phone number you provided </div>
                        </div>
                        <div className='hidden lg:flex flex-shrink-0'>
                            {phoneBtnClick ? <ActiveRadioBtn /> : <InactiveRadioBtnSvg />}
                        </div>
                    </button>
                </div>
            </motion.div>
        </>
    )
};

const ResetPwrdNotification = ({ setShowEPSelect, send, fetchContext, successMessage, setSuccessMessage, resendSuccess, setResendSuccess, usertype }) => {
    const dataContext = useContext(DataContext);
    const [ timer, setTimer ] = useState(true)

    const handleCloseModal = () => {
        dataContext.setShowPwrdResetNotif(false);
        dataContext.setFPswrdComponent(null); 
        setSuccessMessage('');
        setResendSuccess(false)
    };

    function emailSent(words) {
        let n = words.split(" ");
        return n[n.length - 1];
    }

    const handleResend = () => {
        ReactGA.event({
            category: 'User',
            action: 'Resend password via email'
        });
        
        send(
            'forgetpassword', 
            {
                username: dataContext.credentials && dataContext.credentials.username,
                usertype: usertype ? usertype : 'educator',  // Remember to make dynamic, use dataContext.fPswrdComponent
                SchoolUrl: fetchContext.host,
                medium : 'email'
            },
            null,
            { resend : true }
        );
    };

    const successMsgTimeout = () => {
        setTimeout(() => {
            setTimer(false)
        }, 3000);

        return timer && <FormSuccess text={'Resent Successfully'} />
    }

    return (
        <>
            <div className='bg-white w-4/5 sm:w-1/3 rounded-lg p-4 lg:p-6'>
                <div className='flex justify-end'>
                    <span onClick={() => handleCloseModal()} className='cursor-pointer p-3 flex items-center justify-center hover:bg-lightBlue transition-colors duration-200 ease-in-out rounded-full'> 
                        <CloseBlackIcon /> 
                    </span>
                </div>
                <div className='flex flex-col items-center text-center px-4 py-4 justify-center w-full'>
                    <div className=''>
                        <MailSentSvg />
                    </div>
                    <div className='text-headingDark font-bold mb-2 text-lg sm:text-h2'>
                        We’ve sent you an email
                    </div>
                    {resendSuccess && (
                        <div className='mb-2'>
                            {successMsgTimeout()}
                        </div>
                    )}
                    <div className='px-2 lg:px-4 pb-4 mb-3 max-w-full'>
                        <div className='text-headingDark opacity-70 font-semibold text-p max-w-full overflow-x-hidden'>
                            We have sent a recovery link to your email <strong className='opacity-100 w-full font-bold'> {successMessage && emailSent(successMessage)}. </strong>
                            {" "}Kindly check your inbox or spam folder
                        </div>
                    </div>
                    <div className='text-headingDark font-bold text-xs'>
                        <span onClick={() => {dataContext.setShowPwrdResetNotif(false);setShowEPSelect(true)}} className='text-primary cursor-pointer'>Choose another method,</span> 
                        <span onClick={handleResend} className='text-primary cursor-pointer'> Resend link</span> 
                        {" "} or Contact school admin
                    </div>
                </div>
            </div>
        </>
    )
}

const ForgotPswrdModal = () => {
    const dataContext = useContext(DataContext);
    const fetchContext = useContext(FetchContext);
    const [showEPSelect, setShowEPSelect] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resendSuccess, setResendSuccess] = useState(false);
    const currPortal = window.location.pathname.split('/')[2];
    const usertype = currPortal === '' ? 'educator' : replaceChar(currPortal && currPortal, currPortal && currPortal.lastIndexOf('s'), '')

    const send = async (endpoint, credentials, actions, resend) => {
        // console.log(credentials.educator)
        try {
          setLoading(true);
          const { data } = await fetchContext.postRoute(`auth/${endpoint}`, credentials);
        //   console.log(data);
          if(data.status) {
            setSuccessMessage(data.data);
            resend && resend.resend && setResendSuccess(true);
            setLoading(false);
            setError('');
            if (actions) {
                actions.action1()
                actions.action2()
            }
          } else {
              setError(data.message);
            //   console.log(data.message);
              setLoading(false);
          }
        } catch (error) {
            setLoading(false);
            setError(error?.response?.data?.message);
            // setError('Something Went Wrong');
            console.log({error});
        }
    }

    return (
        <>
            { showEPSelect &&
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-30'>
                        <ForgotPassword 
                            setShowEPSelect = {setShowEPSelect} 
                            successMessage={successMessage}
                            setSuccessMessage={setSuccessMessage} 
                            send = {send}
                            loading = {loading}
                            setLoading = {setLoading}
                            error = {error}
                            setError = {setError}
                            usertype = {usertype}
                        />
                    </div>
                    <button className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            { dataContext.showPwrdResetNotif && 
                <>
                    <div className='flex justify-center items-center fixed inset-0 z-30'>
                        <ResetPwrdNotification 
                            setShowEPSelect={setShowEPSelect} 
                            send={send} 
                            fetchContext = {fetchContext}
                            successMessage = {successMessage}
                            setSuccessMessage = {setSuccessMessage}
                            resendSuccess = {resendSuccess}
                            setResendSuccess = {setResendSuccess}
                            usertype = {usertype}
                        />
                    </div>
                    <motion.button variants={backDrop} className='cursor-default z-10 bg-headingDark bg-opacity-50 fixed inset-0 w-full h-full' />
                </>
            }
            { dataContext.showOTPForm &&
                <ConfirmOtp 
                    setShowEPSelect = {setShowEPSelect} 
                    successMessage={successMessage} 
                    send={send} 
                    loading = {loading}
                    setLoading = {setLoading}
                    error = {error}
                    setError = {setError}
                    resendSuccess = {resendSuccess}
                    setResendSuccess = {setResendSuccess}
                    usertype = {usertype}
                />
            }
            {/* {   dataContext.fPswrdComponent === 'students' ? <StudentLoginWOA /> :
                dataContext.fPswrdComponent === 'parents' ? <ParentLogin /> : <EducatorLoginWOA />
            } */}
        </>
    )
}

export { ForgotPassword, ForgotPswrdModal };