import React from 'react';

const ConfirmedSvg = () => (
    <svg width="65" height="60" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.6358 11.398C9.77905 11.398 12.3271 8.84905 12.3271 5.70479C12.3271 2.56053 9.77905 0.0115967 6.6358 0.0115967C3.49256 0.0115967 0.944458 2.56053 0.944458 5.70479C0.944458 8.84905 3.49256 11.398 6.6358 11.398Z" fill="#00DF76"/>
        <path d="M6.63607 0.0113525C6.63349 0.0113525 6.63094 0.0114415 6.62836 0.0114415V11.3976C6.63094 11.3976 6.63349 11.3977 6.63607 11.3977C9.77932 11.3977 12.3274 8.84879 12.3274 5.70452C12.3274 2.56025 9.77932 0.0113525 6.63607 0.0113525Z" fill="#00AB5E"/>
        <path d="M3.41875 5.81773L5.72073 8.11971C5.82113 8.22011 5.98392 8.22011 6.08432 8.11971L9.86352 4.34051C9.96392 4.24011 9.96392 4.07732 9.86352 3.97692L9.17565 3.28904C9.07525 3.18865 8.91246 3.18865 8.81206 3.28904L6.06328 6.03778C5.96289 6.13818 5.8001 6.13818 5.6997 6.03778L4.46788 4.80596C4.36946 4.70754 4.21058 4.70531 4.10945 4.80093L3.42391 5.4491C3.31869 5.5486 3.31635 5.71533 3.41875 5.81773Z" fill="#FFF5F5"/>
        <path d="M9.17525 3.28904C9.07485 3.18865 8.91206 3.18865 8.81167 3.28904L6.62836 5.47235V7.5753L9.86313 4.34053C9.96353 4.24013 9.96353 4.07734 9.86313 3.97692L9.17525 3.28904Z" fill="#DFEBF1"/>
    </svg>
)

export default ConfirmedSvg;