import React, { createContext, useState } from 'react';

const DataContext = createContext();
const { Provider } = DataContext;

const DataProvider = ({ children }) => {
    const [showErr, setShowErr] = useState(false);
    const [showFPswrdModal, setShowFPswrdModal] = useState(false);
    const [showPwrdResetNotif, setShowPwrdResetNotif] = useState(false);
    const [showNewPwrdForm, setShowNewPwrdForm] = useState(true);
    const [showPswrdConfirmed, setShowPswrdConfirmed] = useState(false);
    const [showOTPConfirmed, setShowOTPConfirmed] = useState(false);
    const [showConfirmOTP, setShowConfirmOTP] = useState(true);
    const [showOTPForm, setShowOTPForm] = useState(false);
    const [fPswrdComponent, setFPswrdComponent] = useState(null);
    const [credentials, setCredentials] = useState(null);

    // const handleFPswrdComponentAction = ({ page }) => {
    //   setFPswrdComponent(page)
    //   console.log(page)
    //   // setShowFPswrdModal(true)
    // }

    // iadebayodare@gmail.com - 8080 email, change back after test

  return (
    <Provider
      value={{
        showErr,
        setShowErr,
        showFPswrdModal,
        setShowFPswrdModal,
        showPwrdResetNotif,
        setShowPwrdResetNotif,
        showNewPwrdForm,
        setShowNewPwrdForm,
        showPswrdConfirmed,
        setShowPswrdConfirmed,
        showOTPConfirmed,
        setShowOTPConfirmed,
        showOTPForm,
        setShowOTPForm,
        setFPswrdComponent,
        fPswrdComponent,
        credentials,
        setCredentials,
        showConfirmOTP,
        setShowConfirmOTP
        // handleFPswrdComponent: person => handleFPswrdComponentAction(person)
      }}
    >
      {children}
    </Provider>
  );
};

export { DataContext, DataProvider };
